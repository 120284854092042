var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detection-index",style:({
    background:
      'url(' + require('@/assets/detection_bg.png') + ') no-repeat,#fff',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  })},[_c('div',{staticClass:"d-header"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',[_vm._v("智能检测")])],1),_c('div',{staticClass:"animation"},[_c('div',[(_vm.scanning === false)?_c('div',[_vm._v(_vm._s(_vm.score)+"分")]):_vm._e()]),_c('div',{style:({
        background: 'url(' + require('@/assets/rotate.png') + ') no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      })}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scanning !== false),expression:"scanning !== false"}]},[_c('div',{class:{ scanning: _vm.current === 0, active: _vm.current === 1 }},[_c('i',{staticClass:"iconfont icon-jiance_zhaomingdeng"}),_c('div',[_vm._v("正在检测智能灯...")])]),_c('div',{class:{ scanning: _vm.current === 1, active: _vm.current === 2 }},[_c('i',{staticClass:"iconfont icon-jiance_kongtiao"}),_c('div',[_vm._v("正在检测空调...")])]),_c('div',{class:{ scanning: _vm.current === 2, active: _vm.current === 3 }},[_c('i',{staticClass:"iconfont icon-jiance_chuanglian"}),_c('div',[_vm._v("正在检测窗帘...")])]),_c('div',{class:{ scanning: _vm.current === 3, active: _vm.current === 4 }},[_c('i',{staticClass:"iconfont icon-jiance_zidongmen"}),_c('div',[_vm._v("正在检测自动门...")])]),_c('div',{class:{ scanning: _vm.current === 4, active: _vm.current === 5 }},[_c('i',{staticClass:"iconfont icon-jiance_zhaomingdeng"}),_c('div',[_vm._v("正在检测智能灯...")])]),_c('div',{class:{ scanning: _vm.current === 5, active: _vm.current === 0 }},[_c('i',{staticClass:"iconfont icon-jiance_guangbo"}),_c('div',[_vm._v("正在检测广播...")])])])]),_c('div',{staticClass:"scanning"},[_c('div',[_vm._v(_vm._s(_vm.progress)+"%")]),_c('div',[_vm._v(" 努力扫描中"+_vm._s(_vm.state === 0 ? "." : _vm.state === 1 ? ".." : _vm.state === 2 ? "..." : "....")+" ")])]),_c('van-button',{staticClass:"enter-btn",attrs:{"round":"","type":"info","color":_vm.defineStyle.themeColor},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }