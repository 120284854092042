<template>
  <div
    class="detection-index"
    :style="{
      background:
        'url(' + require('@/assets/detection_bg.png') + ') no-repeat,#fff',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }"
  >
    <div class="d-header">
      <van-icon @click="$router.go(-1)" name="arrow-left" />
      <span>智能检测</span>
    </div>
    <div class="animation">
      <div>
        <div v-if="scanning === false">{{ score }}分</div>
      </div>
      <div
        :style="{
          background: 'url(' + require('@/assets/rotate.png') + ') no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }"
      ></div>
      <div v-show="scanning !== false">
        <div :class="{ scanning: current === 0, active: current === 1 }">
          <i class="iconfont icon-jiance_zhaomingdeng"></i>
          <div>正在检测智能灯...</div>
        </div>
        <div :class="{ scanning: current === 1, active: current === 2 }">
          <i class="iconfont icon-jiance_kongtiao"></i>
          <div>正在检测空调...</div>
        </div>
        <div :class="{ scanning: current === 2, active: current === 3 }">
          <i class="iconfont icon-jiance_chuanglian"></i>
          <div>正在检测窗帘...</div>
        </div>
        <div :class="{ scanning: current === 3, active: current === 4 }">
          <i class="iconfont icon-jiance_zidongmen"></i>
          <div>正在检测自动门...</div>
        </div>
        <div :class="{ scanning: current === 4, active: current === 5 }">
          <i class="iconfont icon-jiance_zhaomingdeng"></i>
          <div>正在检测智能灯...</div>
        </div>
        <div :class="{ scanning: current === 5, active: current === 0 }">
          <i class="iconfont icon-jiance_guangbo"></i>
          <div>正在检测广播...</div>
        </div>
      </div>
    </div>
    <!-- <div class="bubbles">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
    <div class="scanning">
      <div>{{ progress }}%</div>
      <div>
        努力扫描中{{
          state === 0 ? "." : state === 1 ? ".." : state === 2 ? "..." : "...."
        }}
      </div>
    </div>
    <van-button
      class="enter-btn"
      round
      type="info"
      @click="$router.go(-1)"
      :color="defineStyle.themeColor"
      >取消</van-button
    >
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      progress: 0,
      state: 0,
      scanning: true,
      timer: null,
      current: 0,
      score: 0,
      test: null,
    };
  },
  methods: {},
  computed: {},
  mounted() {
    if (this.scanning) {
      this.timer = setInterval(() => {
        if (this.state >= 4) {
          this.state = 0;
        } else {
          this.state++;
        }
        if (this.progress >= 100) {
          this.progress = 0;
        } else {
          this.progress++;
        }
      }, 1000);

      this.test = setInterval(() => {
        if (this.current >= 5) {
          clearInterval(this.test);
          clearInterval(this.timer);
          this.score = 80;
          this.progress = this.score;
          this.scanning = false;
          setTimeout(() => {
            this.$router.push({ path: "/SmartClassroom/Result" });
          }, 1000);
        } else {
          this.current++;
        }
      }, 4000);
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.test);
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
@keyframes rotateBox {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes bubblesAnimation1 {
  0% {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    position: absolute;
    bottom: 60px;
    left: -130px;
    opacity: 0;
  }
  100% {
    position: absolute;
    bottom: 60px;
    left: -130px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation2 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: -70px;
    left: 180px;
    opacity: 0;
  }
  100% {
    bottom: -70px;
    left: 180px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation3 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: 90px;
    left: 160px;
    opacity: 0;
  }
  100% {
    bottom: 90px;
    left: 160px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation4 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: 140px;
    left: 200px;
    opacity: 0;
  }
  100% {
    bottom: 140px;
    left: 200px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation5 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: -110px;
    left: 185px;
    opacity: 0;
  }
  100% {
    bottom: -110px;
    left: 185px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation6 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: -70px;
    left: -220px;
    opacity: 0;
  }
  100% {
    bottom: -70px;
    left: -220px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation7 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: -130px;
    left: -190px;
    opacity: 0;
  }
  100% {
    bottom: -130px;
    left: -190px;
    opacity: 0;
  }
}
@keyframes bubblesAnimation8 {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    bottom: -190px;
    left: -190px;
    opacity: 0;
  }
  100% {
    bottom: -190px;
    left: -190px;
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #fff, 0 0 2px 3px themeRGBColor(0.8), 0 0 1px 32px #fff,
      0 0 32px 25px themeRGBColor(0.8);
  }
  49.9% {
    box-shadow: 0 0 1px 32px #fff, 0 0 32px 25px themeRGBColor(0.8),
      0 0 1px 64px #fff, 0 0 60px 25px themeRGBColor(0.8);
  }
  50% {
    box-shadow: 0 0 0 0 #fff, 0 0 2px 3px themeRGBColor(0.8), 0 0 1px 32px #fff,
      0 0 32px 25px themeRGBColor(0.8);
  }
  100% {
    box-shadow: 0 0 1px 32px #fff, 0 0 32px 25px themeRGBColor(0.8),
      0 0 1px 64px #fff, 0 0 60px 25px themeRGBColor(0.8);
  }
}
.detection-index {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: relative;
  .van-button {
    border-radius: 22px;
    font-size: 15px;
    font-weight: 400;
  }
  .van-button--normal {
    padding: 0 53px;
  }
  .d-header {
    padding-left: 10px;
    padding-top: 14px;
    font-size: 22px;
    font-weight: 400;
    color: $titleColor;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .scanning {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: $themeColor;
    > div:last-child {
      margin-top: 10px;
    }
  }
  .animation {
    width: 145px;
    height: 145px;
    background: radial-gradient(#ffc554 0%, themeRGBColor(0.8) 100%);
    box-shadow: 0 0 1px 5px #fff, 0 0 10px 5px themeRGBColor(0.8);
    border-radius: 50%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    > div:first-child {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 1px 5px #fff, 0 0 2px 30px themeRGBColor(0.1),
        0 0 2px 65px themeRGBColor(0.05);
      border-radius: 50%;
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    > div:nth-child(2) {
      width: 175px;
      height: 175px;
      position: absolute;
      top: 50%;
      left: 50%;
      animation: rotateBox ease-out 3s infinite;
    }
    > div:last-child {
      width: 100%;
      height: 100%;
      overflow: hidden;
      > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        transform: translateX(150%);

        > i {
          font-size: 50px;
        }
        > div:last-child {
          transform: scale(0.9);
          margin-top: 5px;
        }
      }
      > div.scanning {
        transform: translateX(0);
        transition: all 1s ease;
      }
      > div.active {
        transform: translateX(-150%);
        transition: all 1s ease;
      }
    }
  }
  // .bubbles {
  //   position: absolute;
  //   top: 40%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   > div {
  //     background: radial-gradient(
  //       circle,
  //       #ffd379 0%,
  //       #fd9232 76%,
  //       #fd9232 100%
  //     );
  //     border-radius: 50%;
  //     position: absolute;
  //     //   top: 0;
  //     //   left: 0;
  //   }
  // > div:first-child {
  //   width: 18px;
  //   height: 18px;
  //   animation: bubblesAnimation1 ease-out 2s infinite;
  // }
  // > div:nth-child(2) {
  //   width: 18px;
  //   height: 18px;
  //   animation: bubblesAnimation2 ease-out 2s infinite;
  // }
  // > div:nth-child(3) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation3 ease-out 2s infinite;
  // }
  // > div:nth-child(4) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation4 ease-out 2s infinite;
  // }
  // > div:nth-child(5) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation5 ease-out 2s infinite;
  // }
  // > div:nth-child(6) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation6 ease-out 2s infinite;
  // }
  // > div:nth-child(7) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation7 ease-out 2s infinite;
  // }
  // > div:nth-child(8) {
  //   width: 12px;
  //   height: 12px;
  //   animation: bubblesAnimation8 ease-out 2s infinite;
  // }
  // }
  .enter-btn {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>